<template>
  <TTModuleMenu
    :items-tree="menuItems"
    :user-name="userName"
    @sign-out="handleSignOut"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppAsideLeftModuleMenu',
  inject: ['Names'],
  data() {
    return {
      // exitItem: {
      //   caption: 'Выход',
      //   testLabel: 'menu-exit',
      //   to: { name: this.Names.R_AUTH_SIGN_IN },
      //   icon: 'fal fa-sign-out',
      // },
    };
  },
  computed: {
    ...mapGetters('customerInfo', ['unresolvedAppeal', 'expiredCheckpoint', 'isSupervisor']),
    ...mapGetters('acl', ['user', 'isGod']),
    ...mapGetters('changelog', ['hasUnreadedData']),
    userName() {
      return `${this.user.last_name} ${this.user.middle_name} ${this.user.first_name} `;
    },
    mtMenuItems() {
      const items = [
        [
          {
            title: 'Дашборд',
            testLabel: 'menu-home',
            to: { name: this.Names.R_HOME },
            icon: 'fal fa-columns',
          },
          {
            title: 'Все сотрудники',
            testLabel: 'menu-staff',
            to: { name: this.Names.R_STAFF },
            icon: 'fal fa-users',
            children: [{
              title: 'Добавить',
              icon: 'fal fa-plus',
              testLabel: 'menu-staff-add',
              to: {
                name: this.Names.R_STAFF,
                hash: '#add',
              },
            }],
          },
          {
            title: 'Встречи',
            testLabel: 'menu-report-meeting',
            to: { name: this.Names.R_REPORT_MEETING },
            icon: 'fal fa-calendar-check',
            badge: !!this.expiredCheckpoint,
          },
          {
            title: 'Обращения',
            testLabel: 'menu-appeal',
            to: { name: this.Names.R_APPEAL },
            icon: 'fal fa-megaphone',
            badge: !!this.unresolvedAppeal,
          },
          {
            title: 'События',
            testLabel: 'menu-event',
            to: { name: this.Names.R_EVENTS },
            icon: 'fal fa-bell',
          },
          {
            title: 'Треки',
            testLabel: 'menu-tracks',
            to: { name: this.Names.R_TRACKS },
            icon: 'fal fa-project-diagram',
          },
          {
            title: 'Достижения',
            testLabel: 'menu-achievement',
            to: { name: this.Names.R_ACHIEVEMENT },
            icon: 'fal fa-award',
          },
          {
            title: 'О продукте',
            testLabel: 'menu-about',
            to: { name: this.Names.R_ABOUT },
            icon: 'fal fa-question-circle',
            badge: this.hasUnreadedData,
          },
        ],
        [
          {
            title: 'Отчет по задачам наставника',
            to: { name: this.Names.R_REPORT_TUTOR_LIST },
            icon: 'fal fa-check-circle',
            testLabel: 'menu-report-tutor',
          },
        ],
        [
          {
            title: 'Пользователи',
            testLabel: 'menu-user',
            to: { name: this.Names.R_USER },
            icon: 'fal fa-user-tie',
          },
          {
            title: 'Настройки',
            testLabel: 'menu-config',
            to: { name: this.Names.R_CONFIG },
            icon: 'fal fa-cog',
          },
        ],
      ];
      return items;
    },
    menuItems() {
      let items;
      if (!this.enableMTMenu) {
        items = [
          [
            {
              title: 'Дашборд',
              testLabel: 'menu-home',
              to: { name: this.Names.R_HOME },
              icon: 'fal fa-columns',
            },
            {
              title: 'Все сотрудники',
              testLabel: 'menu-staff',
              to: { name: this.Names.R_STAFF },
              icon: 'fal fa-users',
              children: [{
                title: 'Добавить',
                icon: 'fal fa-plus',
                testLabel: 'menu-staff-add',
                to: {
                  name: this.Names.R_STAFF,
                  hash: '#add',
                },
              }],
            },
            {
              title: 'Встречи',
              testLabel: 'menu-report-meeting',
              to: { name: this.Names.R_REPORT_MEETING },
              icon: 'fal fa-calendar-check',
              badge: !!this.expiredCheckpoint,
            },
            {
              title: 'Обращения',
              testLabel: 'menu-appeal',
              to: { name: this.Names.R_APPEAL },
              icon: 'fal fa-megaphone',
              badge: !!this.unresolvedAppeal,
            },
            {
              title: 'События',
              testLabel: 'menu-event',
              to: { name: this.Names.R_EVENTS },
              icon: 'fal fa-bell',
            },
            {
              title: 'Уровни',
              testLabel: 'menu-level',
              to: { name: this.Names.R_LEVEL },
              icon: 'fal fa-project-diagram',
            },
            {
              title: 'Достижения',
              testLabel: 'menu-achievement',
              to: { name: this.Names.R_ACHIEVEMENT },
              icon: 'fal fa-award',
            },
            {
              title: 'О продукте',
              testLabel: 'menu-about',
              to: { name: this.Names.R_ABOUT },
              icon: 'fal fa-question-circle',
              badge: this.hasUnreadedData,
            },
          ],
          [
            {
              title: 'Отчет по активности',
              to: { name: this.Names.R_REPORT_STAFF },
              icon: 'fal fa-chart-bar',
              testLabel: 'menu-report-activity',
            },
            {
              title: 'Отчет по прогрессу',
              to: { name: this.Names.R_REPORT_PROGRESS },
              icon: 'fal fa-chart-pie-alt',
              testLabel: 'menu-report-progress',
            },
            {
              title: 'Аналитика по вопросам',
              to: { name: this.Names.R_REPORT_CONTENT },
              icon: 'fal fa-chart-line',
              testLabel: 'menu-report-content',
            },
          ],
          [
            {
              title: 'Отчет по задачам наставника',
              to: { name: this.Names.R_REPORT_TUTOR_LIST },
              icon: 'fal fa-check-circle',
              testLabel: 'menu-report-tutor',
            },
          ],
          [
            {
              title: 'Локации',
              testLabel: 'menu-location',
              to: { name: this.Names.R_LOCATION },
              icon: 'fal fa-route',
            },
            {
              title: 'Пользователи',
              testLabel: 'menu-user',
              to: { name: this.Names.R_USER },
              icon: 'fal fa-user-tie',
            },
            {
              title: 'Настройки',
              testLabel: 'menu-config',
              to: { name: this.Names.R_CONFIG },
              icon: 'fal fa-cog',
            },
          ],
        ];
      } else {
        items = this.mtMenuItems;
      }
      if (this.isSupervisor && this.isGod) {
        items.push([{
          title: 'Компании',
          testLabel: 'menu-control-panel',
          to: { name: this.Names.R_CONTROL_PANEL },
          icon: 'fal fa-building',
          exact: true,
        }]);
        items.push([{
          title: 'Управление историей изменений',
          testLabel: 'menu-control-news',
          to: { name: this.Names.R_CONTROL_CHANGELOG },
          icon: 'fal fa-newspaper',
          exact: true,
        }]);
      }
      return items;
    },
    enableMTMenu() {
      return this.$di.ff.get('mt_menu');
    },
  },
  methods: {
    handleSignOut() {
      this.$router.push({ path: '/login' });
    },
  },
};
</script>
